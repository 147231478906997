export default {
	hidden: {
		type: Boolean,
		default: false,
	},
	id: {
		type: String,
		default: null,
	},
	size: {
		type: String,
		default: "m",
	},
	text: {
		type: String,
	},
	hint: {
		type: String,
	},
	suggestions: {
		type: Array,
	},
	placeholder: {
		type: String,
		default: "",
	},
	required: {
		type: Boolean,
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	error: {
		// This is to make it possible to pass initial errors from other components or the server
		type: String,
	},
	invalidError: {
		type: String,
		default: "Innholdet er ugyldig",
	},
	emptyError: {
		type: String,
		default: "Dette feltet er obligatorisk",
	},
	maxLengthExceededError: {
		type: String,
		default: "Innholdet er for langt",
	},
	validateOnBlur: {
		type: Boolean,
		default: false,
	},
	emitValid: {
		type: Boolean,
	},
	validation: {
		type: Function,
	},
	type: {
		type: String,
		default: "text",
	},
	hideDetails: {
		type: Boolean,
		default: false,
	},
	highlightValid: {
		type: Boolean,
		default: false,
	},
	autocomplete: {
		type: String,
	},
	autofocus: {
		type: Boolean,
	},
	name: {
		type: String,
	},
	loading: {
		type: Boolean,
		default: false,
	},
	solo: {
		type: Boolean,
		default: false,
	},
	prependInnerIcon: {
		type: String,
	},
	value: {},
	modelValue: {},
	default: {},
	dark: {
		type: Boolean,
	},
	maxLength: {
		type: Number,
	},
	hasBackground: {
		type: Boolean,
		default: false,
	},
	minLength: {
		type: Number,
	},
};
